import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { FirebaseProvider } from './src/FirebaseContext';
import '@fontsource/playfair-display';
import '@fontsource/dm-sans';

export const wrapRootElement = ({ element }) => {
  const theme = extendTheme({
    fonts: {
      body: `'Dm Sans', sans-serif`,
      heading: `'Playfair Display', sans-serif`,
    },
    colors: {
      test: {
        500: '#1B1B1B',
      },
    },
    styles: {
      global: {
        'html, body': {
          height: '100%',
        },
        body: {
          backgroundColor: '#f1f1f1',
        },
        '#root': {
          minHeight: '100%',
        },
      },
    },
  });

  return (
    <FirebaseProvider>
      <ChakraProvider theme={theme}>{element}</ChakraProvider>
    </FirebaseProvider>
  );
};
