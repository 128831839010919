import React, { createContext, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  where,
  initializeFirestore,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import emailjs from '@emailjs/browser';

// TODO https://firebase.google.com/docs/app-check/web/recaptcha-provider?authuser=0&hl=fr
const firebaseConfig = {
  apiKey: 'AIzaSyBEMOINurMwYc2E4HNkOQOpwmhkzR2I43A',
  authDomain: 'cani-connect.firebaseapp.com',
  projectId: 'cani-connect',
  storageBucket: 'cani-connect.appspot.com',
  messagingSenderId: '1011590222886',
  appId: '1:1011590222886:web:f7f68ca04c0141218982e7',
  measurementId: 'G-NYDZMJJ0GH',
};

const GOLDEN_BOOK_KEY = 'goldenbook';

export const FirebaseContext = createContext({
  analytics: null,
  sendEmail: async () => false,
  addGoldenBook: async () => false,
  goldenBooks: [],
});

export const FirebaseProvider = ({ children }) => {
  const [app, setApp] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [db, setDb] = useState(null);
  const [storage, setStorage] = useState(null);
  const [goldenBooks, setGoldenBooks] = useState(null);

  useEffect(() => {
    const init = async () => {
      if (!app) {
        const init = initializeApp(firebaseConfig);
        setApp(init);
        setAnalytics(getAnalytics(init));
        const db = initializeFirestore(init, {
          experimentalForceLongPolling: true,
        });
        setDb(db);
        setStorage(getStorage(init));
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (db && goldenBooks === null) {
      onSnapshot(
        query(collection(db, GOLDEN_BOOK_KEY), where('activated', '==', true)),
        snapshot => {
          setGoldenBooks(
            snapshot.docs
              .map(doc => {
                return doc.data();
              })
              .sort((x, y) => {
                return y.createdAt - x.createdAt;
              })
          );
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db]);

  const sendEmail = async (email, comment) => {
    try {
      await emailjs.send(
        'service_lj9hvhg',
        'template_n9xx0a9',
        {
          from: email,
          message: comment,
        },
        'vtwrMEdMC-gZiQMgj'
      );
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const addGoldenBookDocument = async (url, dogName, description, email) => {
    try {
      return await addDoc(collection(db, GOLDEN_BOOK_KEY), {
        url: url,
        dogName: dogName,
        description: description,
        createdAt: new Date().getTime(),
        activated: false,
        email: email,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const addGoldenBook = async (blob, dogName, description, email) => {
    const randomizedName = uuidv4();
    const storageRef = ref(storage, randomizedName);
    try {
      return await uploadBytes(storageRef, blob).then(async snapshot => {
        const url = await getDownloadURL(snapshot.ref);
        return await addGoldenBookDocument(url, dogName, description, email);
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        analytics: analytics,
        sendEmail: sendEmail,
        addGoldenBook: addGoldenBook,
        goldenBooks: goldenBooks ?? [],
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
