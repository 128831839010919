exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-golden-book-js": () => import("./../../../src/pages/GoldenBook.js" /* webpackChunkName: "component---src-pages-golden-book-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notices-js": () => import("./../../../src/pages/LegalNotices.js" /* webpackChunkName: "component---src-pages-legal-notices-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tariffs-js": () => import("./../../../src/pages/Tariffs.js" /* webpackChunkName: "component---src-pages-tariffs-js" */),
  "component---src-pages-whoiam-js": () => import("./../../../src/pages/Whoiam.js" /* webpackChunkName: "component---src-pages-whoiam-js" */)
}

